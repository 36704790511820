import React from 'react';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import { StaticImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"

import { Link } from "gatsby"


import Grid from '@mui/material/Grid';

const UpdateList = ({ listitems }) => {


const itemsObject  = listitems;

const itemsArray  = Object.values(itemsObject);

const output = itemsArray.map(update => 


<div className='updateSlot'>
      <ListItem>
        <ListItemAvatar>



        <img className='updateAvatar' src='/images/EugenOvidiuChirovici.avif'></img>
  
        </ListItemAvatar>
        <ListItemText primary={update.title} secondary={update.date} />
      </ListItem>

<Grid container spacing={4}>

<Grid md={update.largetype ? 8 : 12} xs={12} item><p className='updateText'><div dangerouslySetInnerHTML={{__html: update.p}}></div></p></Grid>

<Grid className={update.largetype ? 'largetype' : 'smalltype'} md={update.largetype ? 4 : 0} xs={update.largetype ? 12 : 0} item><img className={update.imgtype} height='160px;' src={update.image}/></Grid>




</Grid>




</div>);


  return (

    <List><div>{output} </div></List>

  )
}






UpdateList.defaultProps = {
  listitems: [],


}

UpdateList.propTypes = {
  listitems: PropTypes.arrayOf(PropTypes.object),
}

export default UpdateList
