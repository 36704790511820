import React from 'react';


import { Link } from "gatsby"

import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';


import UpdateList from './updatelist.js';

import { useTheme } from '@mui/material/styles';


const personalUpdates = [


            {date: '16 March 2024',largetype:false, title: "Sleeping Dogs is Out Now", p : "The film Sleeping Dogs is currently in cinemas. You may see a trailer <a target='_blank' title='Eugen Ovidiu Chirovici Sleepign Dogs Preview' href='https://collider.com/russell-crowe-sleeping-dogs-sneak-peek/'>here</a>. "},



            {date: '10 February 2024',largetype:false, title: "Interview", p : "I just gave a new interview for HotNews, a Romanian publication, regarding Sleeping Dogs, the upcoming adaptation of The Book of Mirrors. You may read it <a target='_blank' title='Eugen Ovidiu Chirovici Interview' href='https://www.hotnews.ro/stiri-cultura-26889769-interviu-primul-autor-roman-dupa-carui-carte-face-film-russell-crowe-sunt-parere-cel-mai-bun-rol-lui-din-ultimii-zece-cincisprezece-ani.htm'>here</a>. "},


            {date: '28 January 2024',largetype:false, title: "Film", p : "The trailer for Sleeping Dogs, a film adapatation of the Book of Mirrors, has just been released. You may see it <a title='Sleeping Dogs Movie Film' href='/sleeping-dogs'>here</a>. "},

            {date: '06 September 2022',largetype:false, title: "Film", p : "I\'ve recently got the great news that <a title='Russell Crowe to star in Sleeping Dogs' target='_blank' href='https://variety.com/2022/film/news/russell-crowe-alzheimers-crime-thriller-sleeping-city-toronto-film-festival-1235355479/'>Russell Crowe </a> will lead the cast of Sleeping Dogs, an adaptation of my novel, The Book of Mirrors. The film will be directed by Adam Cooper, who wrote the script with his long time writing partner Bill Collage (Exodus, Assassin's Creed) and produced by Nickel City Pictures. "},



            {date: '17 April 2022',largetype:false, title: "Writing New Book", p : "I\'ve started writing a new book. It looks like it might be my best one yet, having carefully taken on board all of your feedback. Really looking forward to it."},

            {date: '16 May 2019',largetype:false, title: "British Council", p : "Gave a short interview with the <a title='Eugen Ovidiu Chirovici British Council' target='_blank' href='https://www.britishcouncil.org/voices-magazine/writing-crime-novel-english-romanian-language-eugen-chirovici-crime'>British Council</a>. Thank you for having me."},

            {date: '01 January 2019',largetype:false, title: "Support", p : "Thank you to all of you who supported me with the <a target='_blank' href='https://www.penguin.co.uk/books/1111560/the-book-of-mirrors/9781784755133.html'>Book of Mirrors</a>. My next book will be better still"},


            {date: '05 March 2018',largetype:false, title: "Lee Child", p : "Received a great review from <a target='_blank' href='https://www.barnesandnoble.com/w/the-book-of-mirrors-e-o-chirovici/1124015906'>Lee Child</a>. Thank you!"},


            {date: '12 October 2016',largetype:false, title: "The Guardian", p : "Gave an interview with <a target='_blank' title='Eugen OVidiu Chirovici Book of Mirrors' href='https://www.theguardian.com/books/2015/oct/12/uk-romanian-author-eugene-chirovici-book-of-mirrors-english'>The Guardian</a>. Thank you!"},



            ]; 


const bookUpdates = [

           

            {date: '03 September 2019',largetype:false, title: "Translations", p : "The Book of Mirrors has been listed by <a title='The Book of Mirrors' href='https://bookriot.com/most-translated-books/'>BookRiot</a> as Romania's most widely translated book. Thank you, I am most grateful for your continued support."},

            {date: '01 February 2019',largetype:true,imgtype:'delete book', title: "Icelandic Translation", p : "The Book of Mirrors has been translated into Icelandic as <a title='Speglabókin by E.O. Chirovici' rel='nofollow' target='_blank' href='https://www.forlagid.is/vara/speglabokin-2/'>Speglabókin</a>. Þakka þér fyrir! I look forward to feedback from Icelandic readers.", image:"../images/covers/ice.jpg"},


            {date: '14 May 2018',largetype:true,imgtype:'delete book', title: "Chinese Translation", p : "The Book of Mirrors has been translated into Chinese and published by CITIC Press as <a title='电子书 by E.O. Chirovici' rel='nofollow' target='_blank' href='https://www.amazon.com/Book-Mirrors-Chinese-Chirovici/dp/7508674545'>电子书</a>. 谢谢你! I look forward to feedback from Chinese readers.", image:"../images/covers/cn.png"},
            
            {date: '08 April 2018',largetype:true,imgtype:'delete book', title: "Swedish Translation", p : "The Book of Mirrors has been translated into Swedish as <a title='Speglarnas Bok by E.O. Chirovici' rel='nofollow' target='_blank' href='https://www.bokus.com/bok/9789113079851/speglarnas-bok/'>Speglarnas Bok</a>. Tack själv! I look forward to feedback from Swedish readers.", image:"../images/covers/swe.jpg"},

            {date: '21 March 2018',largetype:true,imgtype:'delete book', title: "Hungarian Translation", p : "The Book of Mirrors has been translated into Hungarian as <a title='Tükrök könyve by E.O. Chirovici' rel='nofollow' target='_blank' href='https://www.bokus.com/bok/9789634064886/tukrok-konyve/'>Tükrök Könyve</a>. Köszönöm! I look forward to feedback from Hungarian readers.", image:"../images/covers/trk.png"},

            {date: '06 September 2017',largetype:true,imgtype:'delete book', title: "Polish Translation", p : "The Book of Mirrors has been translated into Polish as <a title='Księga luster by E.O. Chirovici' rel='nofollow' target='_blank' href='https://allegro.pl/oferta/ksiega-luster-eugen-ovidiu-chirovici-10767105164'>Księga Luster</a>. Dziękuję Ci! I look forward to feedback from Polish readers.", image:"../images/covers/pol.png"},

            {date: '18 March 2017',largetype:false, title: "Review — The  Hindu", p : "Read a great review in <a target='_blank' href='https://www.thehindu.com/books/books-reviews/games-of-the-mind/article17526995.ece'>the Hindu</a>, by Ms. Radhika Santhanam. Thank you!"},

            {date: '07 February 2017',largetype:true,imgtype:'delete book', title: "German Translation", p : "The Book of Mirrors has been translated into German and published by Goldmann Verlag as <a title='Das Buch Der Spiegel by E.O. Chirovici' rel='nofollow' target='_blank' href='https://www.amazon.de/Das-Buch-Spiegel-Roman-Chirovici/dp/3442314496'>Das Buch Der Spiegel</a>. Danke schön! I look forward to feedback from German readers.", image:"../images/covers/de.png"},
          
            {date: '05 January 2017',largetype:true,imgtype:'delete book', title: "French Translation", p : "The Book of Mirrors has been translated into French and published by Les Escales as <a title='Jeux De Miroirs by E.O. Chirovici' rel='nofollow' target='_blank' href='https://www.amazon.com/Jeux-de-miroirs/dp/2365692028'>Jeux De Miroirs</a>. Merci beaucoup! I look forward to feedback from French readers.", image:"../images/covers/fr.png"},

            {date: '05 January 2017',largetype:true,imgtype:'delete book', title: "Italian Translation", p : "The Book of Mirrors has been translated into Italian and published as <a title='Libro Delgi Specchi by E.O. Chirovici' rel='nofollow' target='_blank' href='https://www.amazon.com/libro-degli-specchi-Italian-ebook/dp/B01MSBW53A'>Libro Delgi Specchi</a>. Grazie! I look forward to feedback from Italian readers.", image:"../images/covers/it.png"},




              ]; 








function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}



export default function FullWidthTabs() {
  const [value, setValue] = React.useState(0);
  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };






return (



<div className='swiper-no-swiping '>
      <AppBar className='bookTabs' position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label={<Badge badgeContent={5}> Personal </Badge>} {...a11yProps(0)}></Tab>
          <Tab label={<Badge badgeContent={10}> Books </Badge>} {...a11yProps(1)}></Tab>

        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                className='updateSwipe'
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} >


<div className='simpleUpdateList '>
<UpdateList listitems={personalUpdates}></UpdateList>
</div>
        </TabPanel>
        <TabPanel value={value} index={1} >


<div className='simpleUpdateList'>
<UpdateList listitems={bookUpdates}></UpdateList>
</div>
        </TabPanel>


   
  
      </SwipeableViews>
    </div>
  );

};




